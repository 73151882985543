<style lang="scss" src="./index.scss"></style>
<template>
  <div
    :class="{
      'wgt-goods-rank': true,
      'padded': value.padded
    }"
  >
    <div v-if="value.title || value.subtitle" class="wgt-hd">
      <div>
        <span class="title">{{ value.title }}</span>
        <span class="sub-title">{{ value.subtitle }}</span>
      </div>
      <!-- <div class="wgt-more" /> -->
    </div>
    <div class="wgt-bd">
      <!-- 挂件自定义部分 -->
      <div class="list-rank">
        <div v-for="(item, index) in list" :key="`ranks-item__${index}`" class="item-box">

          <SpImage v-if="index+1==1" class="xuhao-img" :src="xuhao_img_1" />
          <SpImage v-if="index+1==2" class="xuhao-img" :src="xuhao_img_2" />
          <SpImage v-if="index+1==3" class="xuhao-img" :src="xuhao_img_3" />
          <div v-if="index+1>3" class="xuhao">{{ index+1 }}</div>



          <SpImage class="pic" :src="item.pics.length>0?item.pics[0]:''" />
          <div class="item-info">
            <div class="name">{{ item.itemName }}</div>
            <SpPrice class="item-price" :value="item.price / 100" :size="18" />
          </div>
          
           <SpImage class="look-btn" :src="look_btn_img" />
        </div>
      </div>

      <!-- 挂件自定义部分 -->
    </div>
  </div>
</template>

<script>
import config from './config'
const xuhao_img_1 = require('@/assets/imgs/decorate/goodsRank_1.png')
const xuhao_img_2 = require('@/assets/imgs/decorate/goodsRank_2.png')
const xuhao_img_3 = require('@/assets/imgs/decorate/goodsRank_3.png')
const look_btn_img = require('@/assets/imgs/decorate/goodsRank_more.png')
export default {
  name: 'GoodsRank',
  wgtName: '商品排行榜',
  wgtDesc: '',
  wgtIcon: 'wgt-goodsrank',
  config: config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {
      xuhao_img_1,
      xuhao_img_2,
      xuhao_img_3,
      look_btn_img,
      list:[]
    }
  },
  computed: { 
    
  },
  watch:{
    'value.num':{
      handler(newVal,oldVal){
        this.getRankData();
      },
      immediate:true
    },
    'value.sort':{
      handler(newVal,oldVal){
        this.getRankData();
      },
      immediate:true
    }
  },
  created() {},
  mounted() {},
  methods: {
    async getRankData(){
      let params = {
        num:this.value.num,
        sort:this.value.sort
      }
      const res = await this.$api.wgt_goodsRank.getGoodsRankList(params);

      
      if(res.list && res.list.length>0){
        this.list = res.list;
        this.value.data = res.list
      }else{
        this.list = this.value.data;
      }
    }
  }
}
</script>
