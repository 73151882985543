import { pickBy } from '@/utils'


export default {
  name: 'goodsRank',
  setting: [
    { label: '标题', key: 'title', component: 'input', value: '商品排行榜' },
    { label: '副标题', key: 'subtitle', component: 'input', value: '最畅销商品' },
    { label: '组件间距', key: 'padded', component: 'switch', value: true },
    {
      label: '显示数量',
      key: 'num',
      component: 'number',
      value: 5,
      min: 5,
      max: 50,
      step: 5,
    },
    {
      label: '排序',
      key: 'sort',
      component: 'radio',
      options: [
        { name: '销量', label: 'sales' },
        { name: '浏览量', label: 'pageviews' }
      ],
      value: 'sales'
    },

    {
      label: '',
      key: 'data',
      component: function (h, { key }) {
        return ''
      },
      value: [
        {
          pics:[''],
          linkPage: '',
          content: '',
          title: '商品名称',
          itemId: '',
          price: 0,
          market_price: 0
        },
        {
          pics:[''],
          linkPage: '',
          content: '',
          title: '商品名称',
          itemId: '',
          price: 0,
          market_price: 0
        },
        {
          pics:[''],
          linkPage: '',
          content: '',
          title: '商品名称',
          itemId: '',
          price: 0,
          market_price: 0
        },
        {
          pics:[''],
          linkPage: '',
          content: '',
          title: '商品名称',
          itemId: '',
          price: 0,
          market_price: 0
        },
        {
          pics:[''],
          linkPage: '',
          content: '',
          title: '商品名称',
          itemId: '',
          price: 0,
          market_price: 0
        }
      ]
    },

  ],

  transformIn: (v) => {
    const { name, base, config, data, list, distributor_id } = v

    // let nData = data;

    // const res = await Api.wgt_goodsRank.getGoodsRankList(v.config);
    // if(res.list && res.list.length>0){
    //   nData = res.list
    // }
    // // console.log('rank is:',res.list);
    // console.log('ssss::',v);




    return {
      name,
      ...base,
      ...config,
      data,
      list,
      distributor_id
    }
  },
  transformOut: (v) => {
    // console.log('输出：：：：',v)
    return pickBy(v, {
      name: 'name',
      base: (v) => {
        return pickBy(v, {
          title: 'title',
          subtitle: 'subtitle',
          padded: 'padded',
          
        })
      },
      config: (v) => {
        return pickBy(v, {
          num: 'num',
          sort: 'sort',

        })
      },
      data: 'data'
    })
  }
}
