import fetch from '../utils/fetch'

// 获取商品排行榜数据
export function getGoodsRankList (query) {
  return fetch({
    url: '/h5app/wxapp/goods/ranking',
    method: 'get',
    params: query
  })
}


